@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

html, body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e293b;
  padding: 0;
  letter-spacing: 0.3px;
  background-color: #F8FAFC !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body::-webkit-scrollbar-track {
  background-color: #E2E8F0;
  border-radius: 0px;
}
body::-webkit-scrollbar-thumb {
  background-color: #718096;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: #E2E8F0;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #718096;
  border-radius: 10px;
}

.custom-shadow {
  -webkit-box-shadow: 0px 8px 100px 0px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0px 8px 100px 0px rgba(0, 0, 0, .2);
  box-shadow: 0px 8px 100px 0px rgba(0, 0, 0, .2);
}